import axios from "axios";
import Cookies from "js-cookie";
import { deleteAllCookies } from "../utils/cookies";
import { getAuth, signInAnonymously } from "firebase/auth";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// URLs where tokens should not be set
const noTokenRequiredRoutes = ["/login", "/sign-up"];

function unAuthorizedResponse() {
  deleteAllCookies();
  localStorage.removeItem("product_list");
  localStorage.removeItem("cartItems");
  window.location.pathname = "/";
}

async function getAnonymousToken() {
  const auth = getAuth();
  let token = Cookies.get("token");
  let userCookie = Cookies.get("user");

  // If token or user cookie does not exist, proceed with sign-in
  if (!token || !userCookie) {
    try {
      const result = await signInAnonymously(auth);
      const accessToken = await result.user.getIdToken();

      // Set the token and user info in the cookie only if they do not exist
      token = Cookies.get("token");
      userCookie = Cookies.get("user");
      
      if (!token && !userCookie) {
        Cookies.set("token", accessToken, { path: '/' });
        Cookies.set("user", JSON.stringify({ id: result.user.uid, isAnonymous: true }), { path: '/' });
        console.log("Anonymous Token Set:", accessToken);
      } else {
        console.log("Token and user cookie were set during execution, skipping re-set.");
      }

      return accessToken;
    } catch (error) {
      console.error("Error during anonymous sign-in:", error);
      throw error;
    }
  } else {
    console.log("Using existing Anonymous Token:", token);
    return token;
  }
}

function removeTokenForNoAuthRoutes(url) {
  if (noTokenRequiredRoutes.some(route => url.includes(route))) {
    const userCookie = Cookies.get("user");
    if (userCookie) {
      const user = JSON.parse(userCookie);
      if (user.isAnonymous) {
        deleteAllCookies();
        console.log("Token and user-related cookies removed for auth page:", url);
      }
    }
  }
}

export async function getCall(url, params = null) {
  removeTokenForNoAuthRoutes(url);

  let token = Cookies.get("token");

  if (!token && !noTokenRequiredRoutes.some(route => url.includes(route))) {
    token = await getAnonymousToken();
    console.log("Anonymous Token Retrieved for Request:", token);
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(url, {
        params: params,
        headers: { ...(token && { Authorization: `Bearer ${token}` }) },
      });
      return resolve(response.data);
    } catch (err) {
      const { status } = err.response;
      console.log("Error Response:", err.response);
      if (status === 401) return unAuthorizedResponse();
      return reject(err);
    }
  });
}

export async function postCall(url, params) {
  removeTokenForNoAuthRoutes(url);

  let token = Cookies.get("token");

  if (!token && !noTokenRequiredRoutes.some(route => url.includes(route))) {
    token = await getAnonymousToken();
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(url, params, {
        headers: { ...(token && { Authorization: `Bearer ${token}` }) },
      });
      return resolve(response.data);
    } catch (err) {
      const { status } = err.response;
      if (status === 401) return unAuthorizedResponse();
      return reject(err);
    }
  });
}

export async function putCall(url, params) {
  removeTokenForNoAuthRoutes(url);

  let token = Cookies.get("token");

  if (!token && !noTokenRequiredRoutes.some(route => url.includes(route))) {
    token = await getAnonymousToken();
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(url, params, {
        headers: { ...(token && { Authorization: `Bearer ${token}` }) },
      });
      return resolve(response.data);
    } catch (err) {
      const { status } = err.response;
      if (status === 401) return unAuthorizedResponse();
      return reject(err);
    }
  });
}

export function deleteCall(url) {
  removeTokenForNoAuthRoutes(url);

  const token = Cookies.get("token");
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(url, {
        headers: { ...(token && { Authorization: `Bearer ${token}` }) },
      });
      return resolve(response.data);
    } catch (err) {
      const { status } = err.response;
      if (status === 401) return unAuthorizedResponse();
      return reject(err);
    }
  });
}

export function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => !isCanceled && resolve(val)).catch((error) => !isCanceled && reject(error));
  });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

