import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import AppLayout from '../../appLayout';
import Button from '../../shared/button/button';
import { buttonTypes } from '../../shared/button/utils';
import styles from "../../../styles/profile/profile.module.scss"; // Importing styles

export default function ProfileEdit() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    gender: "",
    occupation: "",
    about: "",
    photo: null,
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const auth = getAuth();
  const user = auth.currentUser;

  // Fetch existing profile data from MongoDB based on Firebase UID
  useEffect(() => {
    if (user) {
      axios.post('/api/profile', { userId: user.uid })
        .then((response) => {
          setFormData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          setLoading(false);
        });
    }
  }, [user]);

  // Handle form data changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle file upload change
  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
  };

  // Validate fields
  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.phone || !/^[0-9]{10}$/.test(formData.phone)) tempErrors.phone = "Valid phone number is required";
    if (!formData.city) tempErrors.city = "City is required";
    if (!formData.state) tempErrors.state = "State is required";
    if (!formData.zipCode || !/^[0-9]{5,6}$/.test(formData.zipCode)) tempErrors.zipCode = "Valid Zip Code is required";
    
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('userId', user.uid);
      formDataToSubmit.append('name', formData.name);
      formDataToSubmit.append('email', formData.email);
      formDataToSubmit.append('phone', formData.phone);
      formDataToSubmit.append('address', formData.address);
      formDataToSubmit.append('city', formData.city);
      formDataToSubmit.append('state', formData.state);
      formDataToSubmit.append('zipCode', formData.zipCode);
      formDataToSubmit.append('gender', formData.gender);
      formDataToSubmit.append('occupation', formData.occupation);
      formDataToSubmit.append('about', formData.about);
      if (formData.photo) formDataToSubmit.append('photo', formData.photo);

      axios.post('/api/profile', formDataToSubmit, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          alert("Profile updated successfully");
          window.location.href = '/profile'; // Redirect to profile view page
        })
        .catch((error) => {
          console.error("Error updating profile:", error);
        });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AppLayout>
      <form onSubmit={handleSubmit} className={`container pt-5 ${styles.profile_form}`}>
        {/* Image Upload */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Upload Photo (optional)</label>
          <input
            type="file"
            className={`form-control ${styles.form_control}`}
            name="photo"
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>

        {/* Name */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Name</label>
          <input
            type="text"
            name="name"
            className={`form-control ${styles.form_control}`}
            value={formData.name}
            onChange={handleChange}
            required
          />
          {errors.name && <span className={styles.error}>{errors.name}</span>}
        </div>

        {/* Email */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Email</label>
          <input
            type="email"
            name="email"
            className={`form-control ${styles.form_control}`}
            value={formData.email}
            readOnly
          />
        </div>

        {/* Phone */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            className={`form-control ${styles.form_control}`}
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && <span className={styles.error}>{errors.phone}</span>}
        </div>

        {/* Gender */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Gender (optional)</label><br />
          <label>
            <input
              type="radio"
              name="gender"
              value="male"
              checked={formData.gender === 'male'}
              onChange={handleChange}
            /> Male
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="female"
              checked={formData.gender === 'female'}
              onChange={handleChange}
            /> Female
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="other"
              checked={formData.gender === 'other'}
              onChange={handleChange}
            /> Other
          </label>
        </div>

        {/* Occupation */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Occupation (optional)</label>
          <input
            type="text"
            name="occupation"
            className={`form-control ${styles.form_control}`}
            value={formData.occupation}
            onChange={handleChange}
          />
        </div>

        {/* About */}
        <div className={`form-group ${styles.form_group}`}>
          <label>About Yourself (optional)</label>
          <textarea
            name="about"
            className={`form-control ${styles.form_control}`}
            value={formData.about}
            onChange={handleChange}
          />
        </div>

        {/* Address */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Address</label>
          <input
            type="text"
            name="address"
            className={`form-control ${styles.form_control}`}
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        {/* City */}
        <div className={`form-group ${styles.form_group}`}>
          <label>City</label>
          <input
            type="text"
            name="city"
            className={`form-control ${styles.form_control}`}
            value={formData.city}
            onChange={handleChange}
            required
          />
          {errors.city && <span className={styles.error}>{errors.city}</span>}
        </div>

        {/* State */}
        <div className={`form-group ${styles.form_group}`}>
          <label>State</label>
          <input
            type="text"
            name="state"
            className={`form-control ${styles.form_control}`}
            value={formData.state}
            onChange={handleChange}
            required
          />
          {errors.state && <span className={styles.error}>{errors.state}</span>}
        </div>

        {/* Zip Code */}
        <div className={`form-group ${styles.form_group}`}>
          <label>Zip Code</label>
          <input
            type="text"
            name="zipCode"
            className={`form-control ${styles.form_control}`}
            value={formData.zipCode}
            onChange={handleChange}
            required
          />
          {errors.zipCode && <span className={styles.error}>{errors.zipCode}</span>}
        </div>

        <div className="text-center py-3">
          <Button
            button_type={buttonTypes.primary}
            button_text="Save"
            type="submit"
          />
        </div>
      </form>
    </AppLayout>
  );
}

