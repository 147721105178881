import React, { Fragment, useEffect, useState } from "react";
import styles from "../../../styles/profile/profile.module.scss";
import { getAuth } from "firebase/auth";
import AppLayout from "../../appLayout";
import Button from "../../shared/button/button";
import { buttonTypes } from "../../shared/button/utils";
import { useHistory } from "react-router-dom"; // Import useHistory

export default function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false); // To track if profile data is incomplete
  const auth = getAuth();
  const user = auth.currentUser;
  const history = useHistory(); // Initialize useHistory

  // Fetch the profile data from MongoDB
  useEffect(() => {
    if (user) {
      fetch("/api/profile", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: user.uid }),
      })
        .then(response => response.json())
        .then(data => {
          if (!data || !data.phone || !data.address) {
            setIsNewUser(true); // Mark as new user if profile fields are missing
          } else {
            setProfileData(data); // Set the fetched profile data
          }
        })
        .catch(error => console.error("Error fetching profile data:", error))
        .finally(() => setLoading(false)); // Stop loading after fetching
    }
  }, [user.uid]);

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // If new user, prompt for more information
  if (isNewUser) {
    return (
      <AppLayout>
        <div className="container pt-5">
          <h3>Welcome! Please complete your profile information:</h3>
          <Button
            button_type={buttonTypes.primary}
            onClick={() => history.push("/edit-profile")} // Redirect to edit profile page
            button_text="Complete Your Profile"
          />
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div className="container pt-5">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-2">
            <div className={styles.user_avatar}>
              <img
                src={profileData?.photoURL ?? "/path/to/default/avatar.png"}
                alt="user_avatar"
                className={styles.avatar_image}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-10">
            <p className={styles.user_name}>{profileData?.name || user.displayName}</p>
            <p className={styles.user_email}>{user.email}</p>
            <p className={styles.user_phone}>{profileData?.phone}</p>
            <p className={styles.user_address}>{profileData?.address}</p>
            <p className={styles.user_city}>{profileData?.city}</p>
            <p className={styles.user_state}>{profileData?.state}</p>
            <p className={styles.user_zip}>{profileData?.zipCode}</p>

            <Button
              button_type={buttonTypes.primary}
              onClick={() => history.push("/edit-profile")} // Redirect to edit profile page
              button_text="Edit Profile"
            />
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

